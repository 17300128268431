import { type MetaFunction } from '@remix-run/node'

export const meta: MetaFunction = () => [
	{ title: 'Barry McGee - Software Engineer' },
]

export default function Index() {
	return (
		<main className="container flex-1 p-0">
			<div className="mx-auto max-w-7xl">
				<div className="prose prose-slate dark:prose-invert lg:prose-lg">
					<h1 className="mb-8 text-3xl font-bold tracking-tight sm:text-5xl">
						How's it going?
					</h1>

					<div className="relative mb-12 overflow-hidden rounded-xl">
						<img
							src="/img/speaking.jpeg"
							alt="Barry speaking at a conference"
							className="aspect-[16/9] w-full object-cover"
						/>
					</div>

					<div className="max-w-xl">
						<p className="text-xl leading-relaxed text-muted-foreground">
							I'm a Software Engineer with two decades of experience building
							web experiences and user interfaces for products and services such
							as the BBC, Paul Smith, FutureLearn, Kahoot, Ubuntu and currently
							-- Shopify.
						</p>
					</div>
				</div>
			</div>
		</main>
	)
}
